var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-flex justify-content-between align-items-center mb-4"},[_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary",attrs:{"type":"arrow-left-circle","role":"button","size":"2rem"},on:{"click":_vm.back}}),_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)],1)]),_c('b-col',{staticClass:"d-flex align-items-stretch",staticStyle:{"min-height":"70vh"},attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticClass:"mb-4 w-100"},[_c('div',[_c('b-form',{staticClass:"p-5"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('h3',{staticClass:"mb-2"},[_vm._v(" Datos de la cuenta bancaria ")]),_c('div',{staticClass:"text-gray mb-5"},[_vm._v(" Por favor registre los datos de la cuenta bancaria para continuar ")])])]),_c('b-row',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}]},[_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"codigo"}},[_vm._v("Código")]),_c('b-form-input',{attrs:{"id":"codigo","type":"text","placeholder":"Ej: 001","state":_vm.validateState('codigo'),"disabled":_vm.read_only},model:{value:(
                                        _vm.$v.cuentaBancaria.codigo.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.cuentaBancaria.codigo, "$model", $$v)},expression:"\n                                        $v.cuentaBancaria.codigo.$model\n                                    "}}),(
                                        !_vm.$v.cuentaBancaria.codigo.$anyError
                                    )?_c('div',{staticClass:"length-field mt-1"},[_vm._v(" ("+_vm._s(_vm.$v.cuentaBancaria.codigo.$model .length)+" / "+_vm._s(_vm.$v.cuentaBancaria.codigo.$params .maxLength.max)+") ")]):_vm._e(),(
                                        !_vm.$v.cuentaBancaria.codigo.maxLength
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.cuentaBancaria.codigo.$params .maxLength.max)+" caracteres ")]):_vm._e(),(
                                        !_vm.$v.cuentaBancaria.codigo.required
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"9","lg":"9","xl":"9"}},[_c('b-form-group',[_c('label',{attrs:{"for":"nombre_comercial"}},[_vm._v("Descripción")]),_c('b-form-input',{attrs:{"id":"descripcion","type":"text","placeholder":"Ej: Mi cuenta bancaria","state":_vm.validateState('descripcion'),"disabled":_vm.read_only},model:{value:(
                                        _vm.$v.cuentaBancaria.descripcion.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.cuentaBancaria.descripcion, "$model", $$v)},expression:"\n                                        $v.cuentaBancaria.descripcion.$model\n                                    "}}),(
                                        !_vm.$v.cuentaBancaria.descripcion
                                            .$anyError
                                    )?_c('div',{staticClass:"length-field mt-1"},[_vm._v(" ("+_vm._s(_vm.$v.cuentaBancaria.descripcion.$model .length)+" / "+_vm._s(_vm.$v.cuentaBancaria.descripcion .$params.maxLength.max)+") ")]):_vm._e(),(
                                        !_vm.$v.cuentaBancaria.descripcion
                                            .maxLength
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.cuentaBancaria.descripcion .$params.maxLength.max)+" caracteres ")]):_vm._e(),(
                                        !_vm.$v.cuentaBancaria.descripcion
                                            .required
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mt-md-4"},[_c('b-col',{attrs:{"cols":"12","md":"5","lg":"5","xl":"5"}},[_c('b-form-group',[_c('label',{attrs:{"for":"codigo"}},[_vm._v("Número de cuenta")]),_c('b-form-input',{attrs:{"id":"num_cuenta","type":"number","placeholder":"Ej: 7890987621","state":_vm.validateState('num_cuenta'),"disabled":_vm.read_only},model:{value:(
                                        _vm.$v.cuentaBancaria.num_cuenta.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.cuentaBancaria.num_cuenta, "$model", $$v)},expression:"\n                                        $v.cuentaBancaria.num_cuenta.$model\n                                    "}}),(
                                        !_vm.$v.cuentaBancaria.num_cuenta
                                            .$anyError
                                    )?_c('div',{staticClass:"length-field mt-1"},[_vm._v(" ("+_vm._s(_vm.$v.cuentaBancaria.num_cuenta.$model .length)+" / "+_vm._s(_vm.$v.cuentaBancaria.num_cuenta.$params .maxLength.max)+") ")]):_vm._e(),(
                                        !_vm.$v.cuentaBancaria.num_cuenta
                                            .maxLength
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.cuentaBancaria.num_cuenta.$params .maxLength.max)+" caracteres ")]):_vm._e(),(
                                        !_vm.$v.cuentaBancaria.num_cuenta
                                            .required
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"tipo"}},[_vm._v("Tipo de cuenta "),_c('small',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('jautocomplete',{attrs:{"id":"tipo","items":_vm.tipos_cuentas,"item-text":"descripcion","item-value":"id","open-on-focus":"","state":_vm.validateState('tipo'),"disabled":_vm.read_only},model:{value:(_vm.$v.cuentaBancaria.tipo.$model),callback:function ($$v) {_vm.$set(_vm.$v.cuentaBancaria.tipo, "$model", $$v)},expression:"$v.cuentaBancaria.tipo.$model"}}),(_vm.$v.cuentaBancaria.tipo.$error)?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4","xl":"4"}},[_c('label',{attrs:{"for":"banco_id"}},[_vm._v("Banco "),_c('small',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('jautocomplete',{attrs:{"id":"banco_id","items":_vm.bancos,"item-text":"text","item-value":"id","open-on-focus":"","state":_vm.validateState('banco_id'),"disabled":_vm.read_only},model:{value:(_vm.$v.cuentaBancaria.banco_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.cuentaBancaria.banco_id, "$model", $$v)},expression:"$v.cuentaBancaria.banco_id.$model"}}),(_vm.$v.cuentaBancaria.banco_id.$error)?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"puc"}},[_vm._v("Puc "),_c('small',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('jautocomplete',{attrs:{"id":"puc","items":_vm.puc,"item-text":"descripcion","item-value":"id","return-object":"","open-on-focus":"","show-create-button":"","load-async":"","no-null":"","create-method":() => {
                                            _vm.$bvModal.show(
                                                'create-puc-modal'
                                            )
                                        },"disabled":_vm.read_only,"state":_vm.validateState('puc'),"custom-filter":_vm.filterPuc},on:{"text":_vm.filterPucData},scopedSlots:_vm._u([{key:"option",fn:function({ item }){return [_c('option',{class:`text-${
                                                item.tipo === 'S'
                                                    ? 'secondary'
                                                    : 'gray'
                                            }`,attrs:{"disabled":item.tipo === 'S'}},[_vm._v(" "+_vm._s(item.codigo)+" - "+_vm._s(item.descripcion)+" ")])]}}]),model:{value:(_vm.$v.cuentaBancaria.puc.$model),callback:function ($$v) {_vm.$set(_vm.$v.cuentaBancaria.puc, "$model", $$v)},expression:"$v.cuentaBancaria.puc.$model"}}),(_vm.$v.cuentaBancaria.puc.$error)?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1)],1)],1)],1)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)])]),_c('crearPucModalVue',{on:{"newPuc":_vm.setNewPuc}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }